export default {
  formSteps: {
    0: {
      question: 'Neste momento, o que mais poderia ajudá-la?',
      choices: {
        0: 'Pôr as minhas ideias em ordem e receber alguma orientação',
        1: 'Saber quais são as minhas opções (de decisão)',
        2: 'Ter alguém que me possa apoiar nesta situação',
      },
      advices: {
        0: '<p><strong>Pôr as ideias em ordem</strong><br>Se está a viver uma gravidez inesperada, é provável que se sinta <strong>confusa e sem saber o que fazer</strong>. Isso é completamente compreensível! Uma gravidez implica mudanças estruturais e, muitas vezes, provoca medos e incertezas. <br>O nosso objetivo é ajudá-la a <strong>ver as coisas de modo mais claro</strong> e equipá-la com as ferramentas necessárias para percorrer em paz o seu caminho.</p>',
        1: '<p><strong>Conhecer as minhas opções</strong><br>O seu desejo de encontrar informação adequada à sua situação é compreensível. Algumas coisas, tal como o prazo legal para fazer um aborto ou informação relativa a possíveis apoios, parecem complicadas e confusas nesta situação emocionalmente tão difícil. <br>O nosso objetivo é aliviá-la desse peso, oferecendo-lhe toda a informação relevante. Ao <strong>conhecer as suas opções</strong>, sentir-se-á mais <strong>preparada para tomar uma decisão informada</strong>.</p>',
        2: '<p><strong>Alguém com quem contar</strong><br>Uma gravidez inesperada ou problemática pode conduzir-nos rapidamente ao nosso limite. Talvez sinta que esta situação está para lá das suas forças. Por isso, o seu desejo de ter alguém que a ajude e que fique do seu lado é muito humano e compreensível. <br>Independentemente de conseguir identificar, neste momento, alguém que a possa apoiar, <strong>não está sozinha. Nós queremos acompanhá-la</strong> oferecendo-lhe os recursos e informação de que possa precisar.</p>',
      },
    },
    1: {
      question: 'Quantas semanas tem esta gravidez?',
      choices: {
        0: 'Ainda não tenho a certeza',
        1: 'Menos de 7 semanas',
        2: 'Entre 7 e 11 semanas',
        3: 'Mais de 11 semanas',
      },
      advices: {
        0: '<p><strong>A semana de gravidez</strong><br>Possivelmente, descobriu há pouco tempo que está grávida. Talvez já tenha marcado uma consulta com o seu médico, ou esteja prestes a fazê-lo. Ele poderá ajudá-la a verificar de quanto tempo está grávida, e se está tudo bem com a gravidez. O mais provável é que ainda tenha tempo suficiente para tomar uma decisão. <br> Até ter mais informação, procure <strong>respirar fundo</strong>. É precisamente isso que a maioria das mulheres precisa nessa fase. O mais recomendável é <strong>não deixar que a coloquem sob pressão</strong>.</p>',
        1: '<p><strong>A semana de gravidez</strong><br>Está muito no início da sua gravidez. Isto significa que <strong>ainda tem tempo</strong> para amadurecer a sua decisão. A legislação permite que, em Portugal, uma mulher possa abortar por opção durante as primeiras 10 semanas.<br>Por isso, respire fundo e permita que a sua decisão amadureça em paz. Para a maioria das mulheres que já passou por esta situação, este tempo para pensar foi muito benéfico. E, precisamente por esta razão, recomendamos-lhe que <strong>não se coloque nem deixe que a coloquem sob pressão</strong>.</p>',
        2: '<p><strong>A semana de gravidez</strong><br>Neste momento, quer se tenha apercebido logo no início ou apenas mais recentemente, a sua gravidez já tem algumas semanas. Dado que em Portugal, legalmente, a mulher pode abortar por opção durante as 10 primeiras semanas de gravidez, <strong>talvez ainda tenha algum tempo para decidir</strong>.<br>Se já se encontra muito perto da data limite e sente que tem de decidir dentro de poucos dias, ou mesmo horas, é aconselhável não dar um passo irreversível enquanto ainda tiver dúvidas e incertezas. <strong>Lembre-se que merece chegar a uma decisão</strong> com a qual esteja de acordo no fundo do seu coração.</p>',
        3: '<p><strong>A semana de gravidez</strong><br>O primeiro trimestre da gravidez já passou ou está prestes a terminar. Contudo, parece que ainda tem dúvidas quanto ao que fazer agora. Que bom ter decidido começar a procurar soluções!<br>Talvez já saiba que, em Portugal, a mulher pode abortar por opção durante as 10 primeiras semanas de gravidez. Pode ser que isto a descanse, pois liberta-a da decisão "aborto: sim ou não". Por outro lado, pode ser precisamente por isso que se sente ainda mais preocupada.<br>Seja qual for o caso, esperamos que possa encontrar nesta página alguns contributos úteis para a sua situação.</p>',
      },
    },
    2: {
      question: 'De que modo esta gravidez afeta o seu dia a dia?',
      choices: {
        0: 'Eu tento afastar da minha cabeça os pensamentos sobre a gravidez',
        1: 'A gravidez é a única coisa em que eu consigo pensar',
        2: 'Eu penso sobre a gravidez, mas também consigo pensar noutros assuntos',
      },
      advices: {
        0: '<p><strong>Pensamentos sobre a gravidez, o que fazer com eles?</strong><br>Até agora, tem tentado não pensar demasiado na gravidez. É compreensível, e também importante, que não queira deixar-se inundar completamente por múltiplos pensamentos e preocupações.<br> Por outro lado, é bom estar a refletir sobre a situação, perguntando-se quais serão os próximos passos. Este é um local perfeito para começar.</p>',
        1: '<p><strong>Pensamentos sobre a gravidez, o que fazer com eles?</strong><br>As preocupações e angústias relacionadas com a gravidez parecem estar a inundá-la. Isso pode fazê-la sentir-se sobrecarregada e exausta.<br>É verdade que é necessário pensar sobre a situação. Porém, para chegar a uma boa decisão, também é preciso manter a <strong>cabeça fresca</strong>. Por isso, <strong>fazer pausas durante as quais não pensa na questão</strong> é igualmente necessário. Para conseguir fazer essas pausas, pode ser útil dedicar-se a atividades de que gosta.</p>',
        2: '<p><strong>Pensamentos sobre a gravidez, o que fazer com eles?</strong><br>Você conseguiu chegar a um bom equilíbrio para lidar com a gravidez e com as preocupações relacionadas com essa situação. Consegue identificar as dificuldades, o que é algo normal e importante, mas não permite que essas dificuldades a esmaguem. Você evita, deliberadamente, estar sempre a refletir sobre as preocupações, de modo a poder <strong>respirar fundo e refrescar as ideias.</strong><br>Há poucas pessoas que conseguem fazer isso, e essa capacidade vai ajudá-la a tomar uma boa decisão.</p>',
      },
    },
    3: {
      question: 'A minha primeira reação ao descobrir a gravidez:',
      choices: {
        0: 'Medo ou ansiedade',
        1: 'Choque',
        2: 'Alegria',
        3: 'Caos emocional',
      },
      advices: {
        0: '<p><strong>Reação inicial: medo ou ansiedade</strong><br>À primeira vista, a notícia da sua gravidez parece tê-la coberto com um <strong>véu escuro</strong> que não lhe permite ver mais nada. Mas saiba que estes pensamentos e sentimentos são permitidos! Estar consciente que há muitas mulheres que também passam por isso, pode ser uma pequena consolação para si. <br>Até <strong>conseguir</strong> levantar um pouco esse véu e <strong>voltar a ver claramente</strong>, terá de passar por um processo. Além de tudo isto, importa saber que as alterações hormonais próprias do início de uma gravidez não devem ser subestimadas. Estas alterações costumam ter uma grande influência nas nossas emoções mas, por norma, diminuem ao fim de algumas semanas.</p>',
        1: '<p><strong>Reação inicial: choque</strong><br>Esta notícia surpreendente parece ter, realmente, tomado conta de si. Por isso, é completamente compreensível sentir-se como se isto fosse um filme que <strong>não está realmente a acontecer</strong>... <br>Para além do efeito da surpresa, é importante estar consciente que as <strong>alterações hormonais</strong> durante o início da gravidez também podem "virar as coisas do avesso" a nível emocional e este facto não deve ser subestimado. No entanto, o efeito destas alterações emocionais tende a diminuir ao fim de algumas semanas.</p>',
        2: '<p><strong>Reação inicial: alegria</strong><br>Ainda bem que tem dentro de si a capacidade de se alegrar neste primeiro momento, mesmo que surjam outras preocupações que ameacem sufocar esta alegria. Esta sua <strong>primeira reação quer dizer alguma coisa</strong> porque, provavelmente, viveu as emoções diante desta descoberta sem o filtro das dúvidas e medos.<br>É recomendável <strong>dar espaço a esta parte de si</strong> ao tomar a sua decisão, pensando de vez em quando: porque é que eu me senti tão feliz quando descobri que estava grávida?</p>',
        3: '<p><strong>Reação inicial: caos emocional</strong><br>Sejam quais forem os seus pensamentos ou sentimentos mais presentes, saiba que eles são completamente normais. No início de uma gravidez, as <strong>alterações hormonais</strong> são, com frequência, bastante notórias a nível emocional. Estas alterações não devem ser subestimadas. O seu corpo e a sua mente precisam de tempo para se adaptarem. Por isso, nesta fase, as suas emoções podem ser confusas e contraditórias. <br>Após algumas semanas, contudo, há uma certa <strong>calma que regressa</strong> e, com o tempo, tanto o seu corpo como as suas emoções voltam a organizar-se.</p>',
      },
    },
    4: {
      question: 'Qual destes poderia ser o seu próximo passo?',
      choices: {
        0: 'Eu gostava de falar com alguém que tivesse uma perspetiva imparcial sobre o assunto.',
        1: 'Eu gostava de partilhar a situação com alguém em quem eu confio.',
        2: 'Eu ainda não sei o que devo fazer.',
        3: 'Estou a pensar em dar passos concretos para fazer um aborto',
      },
      advices: {
        0: '<p><strong>Próximo passo: conversar com alguém que seja imparcial</strong><br>Quando alguém se encontra numa situação emocionalmente perturbadora, ser acompanhada de modo imparcial pode fazer uma grande diferença. <strong>Um ponto de vista diferente</strong> pode abrir-nos a novas maneiras de olhar para a situação.<br>Se, por agora, não lhe ocorre ninguém a quem possa recorrer, sinta-se à vontade para utilizar o nosso <a href="https://app.profemina.org/pt-pt/problems">Teste de Resolução de Problemas</a></p>',
        1: '<p><strong>Próximo passo: alguém com quem falar</strong><br>Independentemente de estar a pensar no seu companheiro, na sua mãe, ou numa amiga chegada, é extremamente valioso <strong>ter alguém em quem confiar e a quem possa dizer o que pensa com autenticidade</strong>. <br>Esperamos que tenha alguém para <strong>desabafar sem receios</strong> e que a <strong>acompanhe no percurso que for o certo para si </strong>.</p>',
        2: '<p><strong>Próximo passo: sem ideias?!</strong><br>Neste momento, não consegue ver nenhum passo possível de dar. Será que conversar com alguém sobre o assunto poderia ajudá-la? Ou acha que marcar uma consulta com o médico iria esclarecê-la melhor? Através de uma ecografia, poderá descobrir com quantas semanas de gravidez se encontra.<br>Se tiver uma preocupação específica relativa a esta gravidez, talvez o nosso <a href="https://app.profemina.org/pt-pt/problems">Teste de Resolução de Problemas</a> a consiga ajudar.</p>',
        3: '<p><strong>Os próximos passos</strong><br>Por agora, o aborto parece o caminho mais óbvio e está a planear os seus próximos passos nesse sentido.<br>Talvez não se sinta completamente segura dessa decisão e também esteja a considerar alternativas para prosseguir com a gravidez. Continue à escuta do seu coração! Ao dar ouvidos às suas emoções, vai encontrar a liberdade necessária para tomar uma decisão coerente com o seu "eu" mais profundo.</p>',
      },
    },
    5: {
      question: 'Na situação presente, qual dos seguintes cenários lhe traria mais paz?',
      choices: {
        0: 'Ver uma luz ao fundo do túnel',
        1: 'Estar em casa, no meu sofá, e sentir-me confortável com quem realmente sou',
        2: 'Estar na natureza a respirar ar puro',
        3: 'Estar no cume de uma montanha e poder ter uma vista completa de tudo lá em baixo',
        4: 'Estar rodeada de pessoas que me amam e me apoiam',
      },
      advices: {
        0: '<p><strong>Luz ao fundo do túnel</strong><br>Você sente o peso das preocupações relacionadas com a gravidez. Para lhe dar coragem e mostrar que existe esperança, seria necessária uma luz forte, a brilhar ao longe.<br>Na sua situação, o que, concretamente, poderia ser essa fonte de luz? Já consegue ver algum brilho no horizonte? O que poderia fazer, para ajudar essa luz a brilhar com mais força?</p>',
        1: '<p><strong>O meu refúgio: estar em casa no sofá</strong><br>Esta gravidez é uma experiência totalmente nova para si o que, naturalmente, é inquietante. Você precisa de um local onde se sinta segura e confortável. Um refúgio como esse iria fazer com que tudo parecesse menos assustador. <br>Pergunte a si própria: “onde, ou com quem, costumo ter esta sensação de estar em segurança?". Concretamente, o que poderia fazer para viver mais momentos de serenidade?</p>',
        2: '<p><strong>No meio da natureza</strong><br>Esta gravidez é como um peso que lhe dificulta a respiração ou causa uma certa claustrofobia. Para si, a natureza ajuda-a a rejuvenescer. Se sair e respirar ar puro, esse passeio irá certamente abri-la a novas perspetivas.<br>Será possível fazer passeios desses com frequência? Desse modo, iria sentir-se menos sufocada e as suas preocupações iriam diminuir até terem uma proporção possível de gerir. Quais são as outras maneiras através das quais poderia conquistar alguma serenidade no seu dia a dia? O que pode fazer para obter momentos de paz com mais frequência?</p>',
        3: '<p><strong>No cume da montanha: a perspetiva de uma águia</strong><br>É provável que a situação atual a faça sentir-se encurralada. Tem sido difícil para si manter as coisas em perspetiva. As múltiplas preocupações impedem-na de ver a paisagem completa, como se fosse uma águia.<br>Tem tido momentos em que tudo parece mais claro? Existe alguém que a consiga ajudar a olhar para a situação com maior clareza? Quais são os passos concretos que pode dar para ter uma melhor visão geral da situação que atravessa?</p>',
        4: '<p><strong>Rodeada das pessoas que ama</strong><br>As suas preocupações com esta gravidez estão a corroê-la por dentro, e sente que tem de lidar com elas sozinha. Aquilo por que mais anseia, é alguém que seja para si uma fonte de esperança, força e coragem.<br>Quem desempenha, mesmo que só às vezes, esse papel na sua vida? Será possível estabelecer um plano para passar mais tempo com essa(s) pessoa(s)?</p>',
      },
    },
    6: {
      question: 'O que deseja para o futuro?',
      choices: {
        0: 'Poder ter uma vida alegre.',
        1: 'Poder tomar uma decisão com a qual me venha a sentir bem a longo prazo.',
        2: 'Poder ficar feliz com a gravidez.',
      },
      advices: {
        0: '<p><strong>As alegrias da vida</strong><br>O seu desejo é que as alegrias da vida não desapareçam quando tomar uma decisão. É um desejo totalmente compreensível. Descobrir a beleza da vida é o que a torna interessante e faz valer a pena vivê-la.<br>Quais são os aspetos que considera serem os mais bonitos da sua vida? O que pode fazer para os preservar? Será que esta situação pode ser um catalisador para alegrias novas e ainda maiores?</p>',
        1: '<p><strong>Uma boa decisão</strong><br>Mais tarde ou mais cedo, toda a gente passa por uma encruzilhada na vida, diante da qual tem de tomar uma decisão. É compreensível que tenha vontade de não sentir arrependimento quando <strong>olhar para a sua decisão em retrospetiva</strong>. Infelizmente, o caminho mais fácil nem sempre é a melhor opção a escolher.<br>Talvez já se sinta confiante com o rumo que está a tomar. Se gostar de receber mais <strong>contributos de alguém exterior à sua situação</strong>, sinta-se à vontade para fazer o <a href="https://www.profemina.org/pt-pt/node/1519"> Teste do Aborto</a></p>',
        2: '<p><strong>Feliz com a gravidez</strong><br>Assim que descobriu a gravidez, talvez tenha sentido uma certa alegria. No entanto, misturaram-se, depois, outros sentimentos, o que é normal. As emoções mais difíceis <strong>revelam o seu sentido de responsabilidade</strong>, levando-a a ponderar todas as possibilidades.<br>Porém, no final, <strong>o mais importante não é aquilo que sentimos</strong> pois, muitas vezes, não é algo que consigamos controlar, <strong>mas sim aquilo que fazemos com essas emoções</strong>. Quando tomamos uma decisão consciente, geralmente as emoções pacificam-se e conformam-se com essa decisão.</p>',
      },
    },
  },
};
