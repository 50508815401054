export default {
  formSteps: {
    0: {
      question: 'Qual destas atividades gostaria mais de fazer?',
      choices: {
        0: 'Ir de férias espontaneamente durante uns dias, sem nada planeado',
        1: 'Ter uma boa conversa com amigos próximos, enquanto cozinhamos juntos',
        2: 'Preparar uma festa surpresa para uma pessoa de quem gosto',
        3: 'Passar uma tarde tranquila no jardim da minha casa',
        4: 'Testar os meus limites num desporto radical',
      },
      advices: {
        0: '<p><strong>A sua força n.º 1: independência</strong><br>Você adora a sua liberdade e independência, e vive a sua vida de modo autónomo. <strong>Não se deixa derrubar facilmente</strong> quando surgem novos desafios. Para isso é preciso ter força interior e coragem. Já houve muitas situações em que demonstrou ter ambas! <br>Contudo, talvez neste momento a ideia de ter uma criança a assuste, por lhe parecer que terá de abdicar de uma parte dessa independência.<br>Ao mesmo tempo, sendo alguém que gosta de liberdade, tem a capacidade de não se deixar deter face a tarefas e desafios. Ao contrário, pode servir-se dessa liberdade para <strong>fazer as escolhas</strong> que, no futuro, a farão <strong>sentir orgulho nas suas decisões</strong>. </p>',
        1: '<p><strong>A sua força n.º 1: empatia</strong><br>Você é uma pessoa afetuosa e sensível, capaz de sentir o que se passa com as outras pessoas e o que toca os seus corações. Graças à sua <strong>empatia</strong>, é capaz de transmitir segurança e amor, pois sabe como dar conforto e afeto aos outros.<br>No entanto, talvez tenha dificuldade em  interpretar o que se passa no seu interior por causa desta gravidez. É possível que tenha medo de não estar à altura deste desafio. <br>Contudo, com a sua sensibilidade, <strong>descobrirá o que precisa</strong> para enfrentar as situações difíceis que possam surgir.</p>',
        2: '<p><strong>A sua força n.º 1: paixão</strong><br>Quando você investe nalguma coisa, essa coisa avança, pois dedica-se às pessoas e às tarefas com paixão. Deste modo, é capaz de inspirar os outros com a sua coragem e fazer com que tudo progrida. <br>Talvez receie que os desafios da gravidez possam travar o "fogo" que há em si e que a sua vida perca a plenitude. Ao mesmo tempo, tem a capacidade necessária para, com a sua <strong>força de vontade</strong>, crescer com esta situação. Agora, tem uma oportunidade de trazer à tona o melhor de si. Quem, se não você, poderá gerir esta situação com força e confiança?</p>',
        3: '<p><strong>A sua força n.º 1: serenidade</strong><br>Você é uma pessoa que não se deixa abalar, mesmo no meio do caos. Através da sua prudência e calma, aqueles que estão perto de si tendem a sentir-se em paz. Consigo, uma pessoa sente-se num porto seguro. <br>Talvez esteja preocupada com a hipótese de perder essa estabilidade interior por causa dos desafios desta gravidez.<br> Ao mesmo tempo, esta <strong>estabilidade</strong> está profundamente enraizada em si, o que lhe pode dar a confiança necessária para enfrentar a situação, dando <strong>um passo de cada vez</strong>. Desse modo, abrirá o seu próprio caminho, a partir do qual poderá chegar ainda mais longe.</p>',
        4: '<p><strong>A sua força n.º 1: coragem</strong><br>Você adora aventuras, desafios, o desconhecido... vai, de bom grado, até aos seus limites. Para isso, está disposta a assumir os riscos e <strong>sair da sua zona de conforto</strong> e acaba por se tornar uma <strong>inspiração para os outros</strong>, ajudando-os a arriscar novos desafios.<br>Talvez se sinta preocupada por ter de deixar de lado algumas destas experiências durante a gravidez, perdendo certos aspetos que a caracterizam...<br>Por outro lado, já tem a experiência de ultrapassar obstáculos que pareciam intransponíveis. Está na sua natureza não se deixar desmoralizar por este tipo de desafios e, em vez disso, decidir <strong>enfrentar o medo</strong>. Afinal, é assim que transforma as dificuldades em aventuras entusiasmantes.</p>',
      },
    },
    1: {
      question: 'Acabou de descobrir que tem de se mudar para outra cidade por causa do seu emprego. Qual a sua reação inicial?',
      choices: {
        0: 'A minha cabeça fica inundada por uma lista de tarefas a fazer: cancelar o meu contrato atual de arrendamento, procurar um novo lugar para viver, encaixotar todos os meus pertences, etc.',
        1: 'Fico bastante perturbada com a novidade e preciso de algum tempo para organizar as ideias.',
        2: 'Procuro ter uma abordagem positiva à novidade, ainda que precise de algum tempo para a integrar.',
      },
      advices: {
        0: '<p><strong>A sua força n.º 2: competência</strong><br> Você tem o hábito de analisar as situações e ponderar as várias possibilidades até encontrar soluções viáveis. Este é um talento importante!<br> Você usa a razão para ultrapassar situações difíceis ao pensar em passos concretos que a ajudem a chegar ao seu objetivo. A sua <strong>determinação</strong> faz com que os obstáculos não a perturbem. Com esta capacidade, é capaz de resolver até o <strong>mais complexo dos problemas</strong>.</p>',
        1: '<p><strong>A sua força n.º 2: intuição</strong><br>A sua intuição é forte e apurada. A sua reação instintiva é o que a costuma conduzir, apontando para determinada direção, mostrando-lhe uma primeira possibilidade.</p>',
        2: '<p><strong>A sua força n.º 2: equilíbrio</strong><br>Em situações difíceis você costuma dar tanta atenção à sua razão como à sua intuição, tirando partido da sua capacidade de análise e da sua sensibilidade.<br>Você usa a razão para procurar soluções possíveis e planear os seus passos, mas também está consciente do que lhe diz a sua intuição e permite que as suas emoções desempenhem o devido papel no processo de tomada de decisão. Este equilíbrio é a receita ideal para tomar decisões maduras!</p>',
      },
    },
    2: {
      question: 'Se eu tivesse mais tempo no meu dia, eu escolhia...',
      choices: {
        0: 'passar mais tempo com as pessoas de quem gosto.',
        1: 'gastar mais tempo e energia a evoluir pessoalmente e a melhorar os projetos em que estou a trabalhar.',
        2: 'fazer mais coisas que me dão prazer nos meus tempos livres.',
        3: 'focar-me mais em permanecer verdadeira em relação a quem sou realmente.',
      },
      advices: {
        0: '<p><strong>A sua força n.º3: conexão</strong><br>Você não vive apenas para si própria, mas também para os outros. Aquilo que faz sentido para si é "estar lá" para essas pessoas e partilhar a vida com elas. É assim que se sente <strong>verdadeiramente realizada</strong>. Talvez até encontre um propósito ou significado profundo ao cuidar dos outros, porque se sente profundamente feliz quando <strong>age para além de si própria</strong> e se encarrega das outras pessoas. </p>',
        1: '<p><strong>A sua força n.º3: motivação</strong><br>Gosta de aplicar as suas capacidades numa causa maior. Desse modo, ajuda a moldar os projetos nos quais se envolve e, a partir do seu envolvimento, <strong>algo significativo</strong> pode ser criado. Estar completamente absorvida por uma boa causa é algo que a realiza. Talvez se aperceba disto: os momentos em que sente maior satisfação ocorrem quando enfrenta situações desafiantes e contribui para uma <strong>causa maior</strong>.</p>',
        2: '<p><strong>A sua força n.º3: entusiasmo</strong><br>Sabe que a vida é bela e gosta de apreciar essa beleza. Por isso, acaba por encontrá-la em toda a parte: na natureza; em atividades artísticas ou criativas; ou quando conhece outras culturas. Esta <strong>atenção ao mundo exterior</strong> e às suas particularidades realizam-na profundamente. Talvez já tenha constatado isto: mesmo em situações difíceis, consegue reparar na beleza das coisas e encontrar aí satisfação.</p>',
        3: '<p><strong>A sua força n.º3: autenticidade</strong><br>Você sente-se realizada quando, ao ver-se ao espelho, sente orgulho naquilo que é e naquilo que faz. É, evidentemente, uma <strong>pessoa autêntica</strong> e com <strong>valores muito claros</strong>. Por isso, também está disposta a seguir, com firmeza, as suas convicções. Talvez já tenha reparado nisto: em situações difíceis, quando se mantém fiel a si própria, consegue ultrapassar os seus próprios limites e sentir-se realizada.</p>',
      },
    },
    3: {
      question: 'Se acordasse a meio da noite cheia de preocupações, quem seria a primeira pessoa que acordaria para partilhar o que se passa?',
      choices: {
        0: 'O meu companheiro',
        1: 'A minha mãe ou o meu pai',
        2: 'Um dos meus irmãos ou familiares',
        3: 'Um dos meus amigos',
        4: 'Não faria isso com ninguém que conheço',
      },
      advices: {
        0: '<p><strong>A sua força n.º 4: relação de casal</strong><br>Ao seu lado, tem um companheiro com quem partilha as alegrias e as preocupações. Que apoio valioso! Juntos, é mais fácil ultrapassarem dificuldades, tirando o melhor partido delas. Podem <strong>contar um com o outro para se apoiarem e incentivarem</strong> quando surgem desafios.<br> Esperamos que, nesta situação, se tornem <strong>ainda mais próximos</strong> e vivam todas as maravilhas de um amor profundo, que se preocupa primeiro com o outro.</p>',
        1: '<p><strong>A sua força n.º 4: apoio parental</strong><br>Às vezes é bom saber que os nossos pais "estão lá", mesmo na vida adulta! Você sabe que conta com a sabedoria deles. Por isso, agora, eles podem ser um <strong>apoio valioso para a aconselhar</strong>. <br> Os seus pais sabem o que se sente quando se fica à espera de uma criança e, por isso, a vossa relação pode ganhar novos contornos e tornar-se ainda mais próxima.</p>',
        2: '<p><strong>A sua força n.º 4: relações familiares fortes</strong><br>Em situações de mudança, como a que atravessa neste momento, a família forma uma <strong>importante rede de suporte</strong>. São pessoas próximas, que a conhecem desde sempre e que, agora, continuam ao seu lado tanto a nível prático como de partilha de experiências. Esperamos que esse ambiente familiar seja uma fonte de forças que a ajude a descobrir o seu caminho com mais clareza.</p>',
        3: '<p><strong>A sua força n.º 4: amizades</strong><br>"Um amigo verdadeiro vale mais do que dinheiro". Na sua situação, pode ser bom lembrar este ditado pois sabe que pode contar com os seus amigos.<br>Com amigos tão bons e fiéis como os seus, mesmo as caminhadas mais desafiantes tornam-se uma aventura e uma oportunidade de aproximação.</p>',
        4: '<p><strong>A sua força n.º 4: procurar apoio</strong><br>Em vez de se fechar no seu mundo, você decidiu procurar soluções ativamente, o que a trouxe até ao nosso site. Teremos todo o gosto em estar ao seu lado e ajudá-la a encontrar o caminho certo para si. <br>Sinta-se à vontade para descobrir todos os nossos <a href="https://www.profemina.org/pt/node/1652">recursos</a>. O nosso <a href="https://app.profemina.org/pt-pt/problems">Teste de Resolução de problemas</a> também a poderá interessar.</p>',
      },
    },
    4: {
      question: 'No passado, como ultrapassei uma situação difícil?',
      choices: {
        0: 'Tentei ver o lado positivo dessa situação',
        1: 'Nunca perdi de vista os meus objetivos',
        2: 'Falei com outras pessoas',
        3: 'Refleti com calma',
        4: 'Com a confiança básica que tenho na vida',
        5: 'Não sei bem mas, de uma maneira ou de outra, consegui',
      },
      advices: {
        0: '<p><strong>A sua força n.º 5: otimismo</strong><br>Você tem capacidade para dançar à chuva em vez de esperar pelo sol.<br> Esta maneira de estar na vida, tão <strong> positiva</strong>, é muito especial e valiosa! Na situação atual, tão desafiante, esta postura pode dar-lhe força e coragem: sabe que, agora, também existe um lado bom para descobrir, mesmo que ainda não seja evidente. <br> Esperamos que esta sua capacidade - que, no fundo, também é "ver com o coração" - a ajude a encontrar o melhor para a sua vida.</p>',
        1: '<p><strong>A sua força n.º 5: perseverança</strong><br>Ter um objetivo dá-lhe coragem e força para seguir em frente e enfrentar os desafios da vida, porque a ajuda a ver para lá dos obstáculos. Provavelmente, já percebeu que até é capaz de sair de situações difíceis ainda mais forte do que antes. <br> Nesta fase, em especial, esse foco pode ajudá-la a identificar uma decisão com a qual se sinta bem a longo prazo. Deixamos-lhe algumas questões que poderá colocar a si própria: "Qual das escolhas me ajudaria a crescer mais?" "De que maneira estas circunstâncias inesperadas podem ser transformadas em algo bom?"</p>',
        2: '<p><strong>A sua força n.º 5: recetividade</strong><br>Você sabe que, efetivamente, é na partilha que "as dores se dividem e as alegrias se multiplicam". Para si, é bom partilhar preocupações e pensamentos com outras pessoas que conheça e em quem confie. Ao fazê-lo, conseguirá organizar-se interiormente e descobrir perspetivas completamente novas.<br> Você sabe que deixar-se acompanhar não é sinal de fraqueza, mas de força!<br>De modo especial nesta situação, desejamos que encontre um espaço de partilha com outras pessoas, através das quais se sinta verdadeiramente apoiada e onde possa ouvir bons conselhos! Ao fazê-lo, talvez descubra diante de si o caminho possível de percorrer.</p>',
        3: '<p><strong>A sua força n.º 5: tranquilidade</strong><br>Os momentos de silêncio ajudam-na a recuperar forças, especialmente em situações difíceis, quando não param de surgir diferentes pensamentos e emoções. Assim, o seu estado de espírito, antes tão agitado, pode voltar gradualmente a uma maior serenidade. É assim que poderá organizar as emoções e dar-lhes o valor adequado. <br>Conseguir estes momentos de maior paz pode ser algo importante para chegar a uma boa decisão nesta situação. Deste modo, pode entrar em contacto com o seu eu mais profundo e identificar a oportunidade que está por descobrir neste desafio que atravessa.</p>',
        4: '<p><strong>A sua força n.º 5: confiança</strong><br>Esta certeza, profunda e tão valiosa, de que tudo tem um sentido, parece acompanhá-la pela vida fora. Mesmo tendo vivido experiências difíceis, você agarra-se a ela. Provavelmente já percebeu que, mesmo os maiores desafios, podem esconder coisas boas.<br>Neste momento, esta constatação pode ajudá-la, porque sabe que a vida vai colaborar. Trata-se de uma convicção que lhe dá a oportunidade de tirar o melhor partido da situação.</p>',
        5: '<p><strong>A sua força n.º 5: mestre na arte de viver</strong><br> Você tem dentro de si muita força e coragem: isto torna-se óbvio porque, mesmo sem saber muito bem como, tem sido capaz de ultrapassar os desafios que a vida lhe coloca. Se não fosse assim, não teria chegado onde chegou. <br>Recordar os desafios que já ultrapassou pode dar-lhe a confiança necessária para lidar com os problemas relacionados com esta gravidez. E mostrar-lhe que, mais uma vez, vai ser capaz de se ultrapassar a si própria.</p>',
      },
    },
  },
};
