const finalQuestion = 'Aquilo que mais me pode ajudar agora é:';

const finalChoicesGeneral = {
  0: 'alguém que me escute e esteja ao meu lado',
  1: 'tempo para mim própria',
  2: 'um plano',
  3: 'que o meu companheiro diga "sim" a esta criança',
  4: 'apoio concreto a nível prático ou financeiro',
};

// Pfad 1: Er will das Kind nicht
// Pfad 8.5: Vergewaltigung
// Path 1: He doesn't want the child
// Path 8.5: Rape
const finalChoicesException = {
  0: 'alguém que me escute e esteja ao meu lado',
  1: 'tempo para mim própria',
  2: 'um plano',
  3: 'apoio concreto a nível prático ou financeiro',
};

const adviceSomebodyWhoListensToMe = '<p><strong>E agora, como continuar?</strong><br>Será que há <strong>alguém próximo de si</strong> em quem confie e com quem possa falar? Uma pessoa com quem se sinta segura?<br><br>É claro, e você bem sabe, que ninguém pode tomar esta decisão por si. Ainda assim, é normal que as pessoas se apoiem e acompanhem umas às outras. Esperamos ter conseguido dar-lhe algum apoio neste acompanhamento digital. No entanto, no momento em que quiser, pode entrar em contacto com uma counsellor <i>profemina</i> para ter um acompanhamento personalizado: <a href="https://www.profemina.org/pt-pt/sobre-nos/formulario-contacto" target="_blank" style="color: #55855d">Contacte nos</a></p>';
const adviceTimeForMyself = '<p><strong>E agora, como continuar?</strong><br>Pode decidir dar a si própria o tempo que quiser para se dedicar ao que lhe faz bem e a ajuda a organizar-se interiormente.<br><br>É claro, e você bem sabe, que ninguém pode tomar esta decisão por si. Ainda assim, é normal que as pessoas se apoiem e acompanhem umas às outras. Esperamos ter conseguido dar-lhe algum apoio neste acompanhamento digital. No entanto, no momento em que quiser, pode entrar em contacto com uma counsellor <i>profemina</i> para ter um acompanhamento personalizado: <a href="https://www.profemina.org/pt-pt/sobre-nos/formulario-contacto" target="_blank" style="color: #55855d">Contacte nos</a></p>';
const advicePlan = '<p><strong>E agora, como continuar?</strong><br>Por vezes é útil colocar as ideias e as emoções no papel, para se conseguir organizar. Pode, simplesmente, tentar dar um passo de cada vez. Não é preciso fazer tudo ao mesmo tempo. Pode acontecer que, a cada passo, surja uma nova perspetiva, a partir da qual poderá continuar a refletir. <br><br>É claro, e você bem sabe, que ninguém pode tomar esta decisão por si. Ainda assim, é normal que as pessoas se apoiem e acompanhem umas às outras. Esperamos ter conseguido dar-lhe algum apoio neste acompanhamento digital. No entanto, no momento em que quiser, pode entrar em contacto com uma counsellor <i>profemina</i> para ter um acompanhamento personalizado: <a href="https://www.profemina.org/pt-pt/sobre-nos/formulario-contacto" target="_blank" style="color: #55855d">Contacte nos</a></p>';
const adviceSupport = '<p><strong>E agora, como continuar?</strong><br>Na sua situação, há muitas mulheres que procuram ajudas concretas a nível prático ou financeiro. Felizmente existem apoios da Segurança Social e de entidades privadas. Pode informar-se sobre alguns deles na nossa página, na secção dedicada a "Orientação e Apoio". Será que existem pessoas próximas de si que a podem ajudar?<br><br>É claro, e você bem sabe, que ninguém pode tomar esta decisão por si. Ainda assim, é normal que as pessoas se apoiem e acompanhem umas às outras. Esperamos ter conseguido dar-lhe algum apoio neste acompanhamento digital. No entanto, no momento em que quiser, pode entrar em contacto com uma counsellor <i>profemina</i> para ter um acompanhamento personalizado: <a href="https://www.profemina.org/pt-pt/sobre-nos/formulario-contacto" target="_blank" style="color: #55855d">Contacte nos</a></p>';

const advicePartnerSaysYesGeneral = '<p><strong>E agora, como continuar?</strong><br>É compreensível e natural que, neste momento, você queira ter apoio do seu companheiro!<br><br>A experiência mostra que, ao início, muitos homens têm dificuldade em envolver-se na gravidez. Ao princípio, as dúvidas e preocupações costumam estar em primeiro plano. É natural que o futuro pai precise de mais tempo do que você para aceitar a nova situação com a cabeça e o coração. Pode parecer irrealista neste momento, mas a maioria dos homens acaba por assumir a responsabilidade, transformando-se em pais orgulhosos dos seus filhos. Talvez vos ajude a ambos se, agora, se ouvir e escutar o seu eu interior para tentar perceber qual é a decisão certa para si.<br><br>É claro, e você bem sabe, que ninguém pode tomar esta decisão por si. Ainda assim, é normal que as pessoas se apoiem e acompanhem umas às outras. Esperamos ter conseguido dar-lhe algum apoio neste acompanhamento digital. No entanto, no momento em que quiser, pode entrar em contacto com uma counsellor <i>profemina</i> para ter um acompanhamento personalizado: <a href="https://www.profemina.org/pt-pt/sobre-nos/formulario-contacto" target="_blank" style="color: #55855d">Contacte nos</a></p>';
// Spezialfall für Pfad 2: es zwischen uns kompliziert ist
// Special Case for Path 2: a nossa relação é complicada
const advicePartnerSaysYesTree211 = '<p><strong>E agora, como continuar?</strong><br>Você referiu que a melhor ajuda para si, agora, era o seu companheiro dizer "sim" a esta criança. É compreensível e natural que seja esse o seu desejo. E a grande maioria das mulheres, na sua situação, sente o mesmo!<br><br>A experiência mostra que, para muitos homens, inicialmente é difícil envolverem-se na gravidez. Especialmente se a relação for complicada. Num primeiro momento, as dúvidas e preocupações ficam, com frequência, em primeiro plano. É natural que o futuro pai precise de mais tempo do que você para aceitar a nova situação com a cabeça e o coração. Talvez lhe pareça irrealista neste momento, mas a maioria dos homens acaba por aceitar as suas responsabilidades. E até se tornam pais orgulhosos dos seus filhos. Pode ajudar-vos, a ambos, se agora se ouvir a si própria e ao seu eu interior, procurando a decisão que, para si, é a certa. <br><br>É claro, e você bem sabe, que ninguém pode tomar esta decisão por si. Ainda assim, é normal que as pessoas se apoiem e acompanhem umas às outras. Esperamos ter conseguido dar-lhe algum apoio neste acompanhamento digital. No entanto, no momento em que quiser, pode entrar em contacto com uma counsellor <i>profemina</i> para ter um acompanhamento personalizado: <a href="https://www.profemina.org/pt-pt/sobre-nos/formulario-contacto" target="_blank" style="color: #55855d">Contacte nos</a></p>';
const advicePartnerSaysYesTree212 = '<p><strong>E agora, como continuar?</strong><br>Você referiu que a melhor ajuda para si, agora, era o seu companheiro dizer "sim" a esta criança. É compreensível e natural que seja esse o seu desejo. E a grande maioria das mulheres, na sua situação, sente o mesmo!<br><br>A experiência mostra que, inicialmente, para muitos homens é difícil envolverem-se na gravidez. Especialmente se a relação for complicada. Num primeiro momento, as dúvidas e preocupações ficam, com frequência, em primeiro plano. É natural que o futuro pai precise de mais tempo do que você para aceitar a nova situação com a cabeça e o coração. Talvez lhe pareça irrealista neste momento, mas a maioria dos homens acaba por aceitar as suas responsabilidades. E até se tornam em pais orgulhosos dos seus filhos. Pode ajudar-vos a ambos se, agora, se ouvir a si própria e ao seu eu interior, procurando a decisão que para si é a certa.<br><br>É claro, e você bem sabe, que ninguém pode tomar esta decisão por si. Ainda assim, é normal que as pessoas se apoiem e acompanhem umas às outras. E é exatamente para isso que nós estamos aqui. Esperamos ter conseguido dar-lhe algum apoio neste acompanhamento digital. No entanto, no momento em que quiser, pode entrar em contacto com uma counsellor <i>profemina</i> para ter um acompanhamento personalizado: <a href="https://www.profemina.org/pt-pt/sobre-nos/formulario-contacto" target="_blank" style="color: #55855d">Contacte nos</a></p>';
// Path 2.3.1 & 2.3.2
// das Kind ist von einem anderen
// a criança é de outro homem
const advicePartnerSaysYesTree23 = '<p><strong>E agora, como continuar?</strong><br>Você referiu que a melhor ajuda para si, agora, era o seu companheiro dizer "sim" a esta criança. É compreensível e natural que seja esse o seu desejo. A experiência mostra que, inicialmente, para muitos homens é difícil envolverem-se na gravidez. Sobretudo quando a criança é de outro homem. Num primeiro momento, as preocupações, a desilusão e as dúvidas estão, provavelmente, em primeiro plano. É natural que ele precise de tempo para integrar a nova situação, com a cabeça e o coração. Pode ajudar-vos a ambos se, agora, se escutar a si própria e ao seu eu interior. Assim, poderá perceber qual é a decisão certa para si. Isto dará, ao seu companheiro, o espaço necessário para encontrar o próprio caminho e, talvez, assumir esta criança. É algo que acontece com bastante frequência! É claro, e você bem sabe, que ninguém pode tomar esta decisão por si. Ainda assim, é normal que as pessoas se apoiem e acompanhem umas às outras. E é precisamente para isso que nós estamos aqui. Esperamos ter conseguido dar-lhe algum apoio neste acompanhamento digital. No entanto, no momento em que quiser, pode entrar em contacto com uma counsellor <i>profemina</i> para ter um acompanhamento personalizado: <a href="https://www.profemina.org/pt-pt/sobre-nos/formulario-contacto" target="_blank" style="color: #55855d">Contacte nos</a></p>';

// Text Components / Duplications
// NOT IN USE YET
const adviceTextBothWays = '<br><br><strong>Olhe para os dois caminhos - a escolha é sua!</strong><br>Neste momento a sua dúvida é: “aborto - sim ou não?“. Seja qual for a sua decisão, ambas têm um impacto na sua vida. Pode ser útil refletir sobre algumas questões que podem ajudá-la a abrir perspetivas e a aproximar-se de uma decisão amadurecida.<br>';
const adviceTextBothWaysIntro = 'Imagine que já tinha tomado a sua decisão e que, ao olhar para trás, conseguia dizer: Esta foi uma ótima decisão!';

// CANNOT BE INSERTED YET -> NEEDS TO BE CHECKED WHETHER THERE IS SEEMLESS TEXT TRANSITION
// BC THIS COMPONENT NO FULL SENTENCE!!!
const adviceTextTree1BiggestConcernsOption1 = '<strong>A sua maior preocupação</strong><br> Provavelmente, a gravidez, por si só, já lhe traz alguma confusão. Os seus pensamentos podem ter disparado a toda a velocidade. É possível que tivesse idealizado uma situação totalmente diferente para quando passasse por uma gravidez. Se o pai da criança também se mostra crítico em relação a esta gravidez, isso pode preocupá-la...';
const adviceTextTree1BiggestConcernsOption2 = '<strong>A sua maior preocupação</strong><br>Provavelmente, a gravidez, por si só, já lhe traz alguma confusão. Os seus pensamentos podem ter disparado a toda a velocidade. É possível que tivesse idealizado uma situação totalmente diferente para quando passasse por uma gravidez. Se o pai da criança também se mostra crítico em relação a esta gravidez, isso pode levá-la ao seu limite...';

const adviceTextTree21BiggestConcern = '<strong>A sua maior preocupação...</strong><br>A vossa relação já terminou mas, agora, você está à espera de um filho dele. À primeira vista, parece não haver pior altura possível para uma gravidez. Talvez isto esteja a levá-la aos seus limites...<br><br>Talvez existissem tensões antigas na vossa relação e os últimos tempos não tenham sido fáceis.';
const adviceTextTree22BiggestConcern = '<strong>A sua maior preocupação...</strong><br>É provável que os últimos tempos não tenham sido fáceis para si, devido às tensões já antigas da sua relação. Isto, por si só, provoca muitas dúvidas.<br><br>Nessas circunstâncias, acrescentar o desafio de uma gravidez pode fazê-la sentir-se no limite.';
const adviceTextTree23BiggestConcern = '<strong>A sua maior preocupação...</strong><br>Talvez, na sua relação, as coisas estivessem tensas há bastante tempo. No fundo, talvez você estivesse à procura, noutro homem, daquilo que lhe fazia falta.<br>Ou então talvez as coisas até estivessem a correr bem para si mas, ainda assim, este episódio aconteceu e não é possível voltar atrás. De qualquer maneira, agora, está grávida de outro homem (ou suspeita disso).<br><br>De repente, tudo mudou. E, provavelmente, movem-na os mais diversos pensamentos, sentimentos, preocupações e receios. É completamente natural que esta situação a faça sentir-se a chegar ao seu limite.';

const adviceTextTree3BiggestConcern = '<strong>A sua maior preocupação...</strong><br>Pode haver muitas razões para pensar que é inapropriado ter uma criança nesta fase da vida. Engravidar nesta situação pode ser confuso porque você prefere planear bem os acontecimentos.<br><br>Talvez seja especialmente difícil porque quer dar ao seu filho aquilo que ele precisa e quer estar disponível quando ele aparecer na sua vida. Na verdade, é isso mesmo que define o coração de uma mãe: querer o melhor para o seu filho. Por isso, é natural que tenha dúvidas quanto ao que fazer agora.<br><br>';
const adviceTextBullet1 = '<li>Em tempos difíceis, o que lhe dá força? Para que lado seria bom dirigir-se?</li>';
const adviceTextBullet2 = '<li>No futuro, gostará de considerar que tomou a decisão certa. Quais são os sinais de uma boa decisão? O que a deixaria particularmente orgulhosa? </li>';
const adviceTextBullet3 = '<li>Quais são os sinais que lhe indicam que tomou uma boa decisão?</li><li>O que a faria sentir-se particularmente orgulhosa?</li>';

export {
  finalQuestion,
  finalChoicesGeneral,
  finalChoicesException,
  adviceSomebodyWhoListensToMe,
  adviceTimeForMyself,
  advicePlan,
  adviceSupport,
  advicePartnerSaysYesGeneral,
  advicePartnerSaysYesTree211,
  advicePartnerSaysYesTree212,
  advicePartnerSaysYesTree23,
  adviceTextBothWays,
  adviceTextBothWaysIntro,
  adviceTextTree1BiggestConcernsOption1,
  adviceTextTree1BiggestConcernsOption2,
  adviceTextTree21BiggestConcern,
  adviceTextTree22BiggestConcern,
  adviceTextTree23BiggestConcern,
  adviceTextTree3BiggestConcern,
  adviceTextBullet1,
  adviceTextBullet2,
  adviceTextBullet3,
};
